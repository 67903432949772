import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {getToken} from '../utils/auth'
import config from '../config';

const token = getToken('adminToken');

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.cmsUrl,
    prepareHeaders(headers){
      if(token){
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    }
  }),
  endpoints: () => ({})
})
