const parseResponse = async (request) => {
  try {
    const response = await request;
    return { payload: response.data };
  } catch (error) {
    if (error.response && error.response.data) {
      
      if (error.response.data.message?.toLowerCase().includes('Not found')) return { payload: [] };
      if (error?.response?.status === 500) {
        return { error: 'Something went wrong, please try again later' };
      }
      if (error.response.data.msg) return { error: error.response.data.msg };
      return {
        error: error.response.data,
      };

    } else {
      return { error: 'Something went wrong, please try again later' };
    }
  }
};

export default parseResponse;
