import React from 'react';
import { Form } from 'react-bootstrap';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@mui/material';

export default function FormControl({
  value,
  type = 'text',
  onChange,
  name,
  error,
  hideLabel = false,
  label,
  readOnly = false,
  placeholder = '',
}) {
  const hasErrors = !isEmpty(error);
  return (
    <div>
      <Form.Group>
        {!hideLabel && (
          <Form.Label htmlFor={name}>
            <Typography variant='subtitle2'>{startCase(label)}</Typography>
          </Form.Label>
        )}
        <Form.Control
          readOnly={readOnly}
          id={name}
          autoComplete='off'
          className='form-field'
          placeholder={placeholder}
          value={value}
          isInvalid={hasErrors}
          name={name}
          type={type}
          onChange={(event) => onChange(event.target.value)}
        />
        {hasErrors && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
      </Form.Group>
    </div>
  );
}
