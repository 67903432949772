export const getYearList = (initialYear) => {
  const currentYear = new Date().getFullYear() - 1;
  const years = [];
  let startYear = initialYear;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

/** formats the date in dd-mm-yyyy given a separator. default separator is '-' */
export const formatDate = (date, separator = '-') => {
  const newDate = new Date(date);
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();
  const year = newDate.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join(separator);
};

/** returns true if the firstDate is after the secondDate */
export const isDateTimeAfter = (firstDate, secondDate) => {
  const dateA = new Date(firstDate);
  const dateB = new Date(secondDate);

  return dateA.getTime() > dateB.getTime();
};

/** returns the time difference in hours between firstDate and secondDate */
export const getTimeDifferenceInHours = (firstDate, secondDate) => {
  const dateA = new Date(firstDate);
  const dateB = new Date(secondDate);

  return (dateA.getTime() - dateB.getTime()) / 3600000;
};
