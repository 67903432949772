import React from 'react';
import './styles.css';
import Logo from '../../images/footer-logo.png';

export default function Footer() {
  return (
    <div className='page-footer'>
      <p>Powered by</p>
      <img className='footer-logo' src={Logo} alt='logo' />
    </div>
  );
}
