import React, { useState } from 'react';
import './styles.css';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles = {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${slides[currentIndex].url})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '10px',
  };

  const next = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const previous = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='slider-wrapper'>
      <div className='slider'>
        <div className={`arrow-wrapper ${currentIndex === 0 ? 'hideElement' : ''}`}>
          <AiFillCaretLeft className='arrow' onClick={previous} />
        </div>
        <div style={slideStyles}></div>
        <div className={`arrow-wrapper ${currentIndex === slides.length - 1 ? 'hideElement' : ''}`}>
          <AiFillCaretRight className='arrow' onClick={next} />
        </div>
      </div>
      <div className='slider-dots-container'>
        {slides.map((slide, slideIndex) => {
          return (
            <span
              onClick={() => goToSlide(slideIndex)}
              key={`${slide?.title}-${slideIndex}`}
              className={`slider-dot ${currentIndex === slideIndex ? 'current-dot' : ''}`}
            >
              &#9679;
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
