import { encryptText, decryptText } from '../utils/crypto';
import jwtDecode from 'jwt-decode';

export const setToken = (token, key = 'token') => {
  localStorage.setItem(key, encryptText(token));
};

export const getToken = (key = 'token') => {
  const token = localStorage.getItem(key);
  try {
    const decryptedToken = decryptText(token);
    return decryptedToken;
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (key = 'token') => {
  try {
    const token = getToken(key);
    const currentTime = new Date() / 1000;

    if (!token || jwtDecode(token).exp < currentTime) {
      localStorage.removeItem(key);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserFromStorage = (key = 'user') => {
  try {
    const storedUser = localStorage.getItem(key);
    const user = storedUser ? JSON.parse(decryptText(storedUser)) : null;
    return user;
  } catch (error) {
    return null;
  }
};

export const saveUserToStorage = (user, key = 'user') => {
  try {
    localStorage.setItem(key, encryptText(JSON.stringify(user)));
    return;
  } catch (error) {
    return;
  }
};
