import { createSlice } from '@reduxjs/toolkit';
//action types
const USER_SUBSCRIBED = 'subscription/userSubscribed';

//action creators
export const userSubscribed = (subscription) => {
  return {
    type: USER_SUBSCRIBED,
    payload: subscription,
  };
};

// reducer
const initialState = {
  activeSubscription: {},
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case USER_SUBSCRIBED: {
      return { ...state, activeSubscription: action.payload };
    }
    default:
      return state;
  }
}

// refactor in future
export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    notification: '',
    expired: false,
    reminder: false,
  },
  reducers: {
    subscriptionHasExpired: (state) => {
      return {
        ...state,
        expired: true,
        notification:
          'Dear Learner, your subscription has expired. Please select a package to continue revising',
      };
    },
    subscriptionAboutToExpire: (state) => {
      return {
        ...state,
        notification:
          'Dear Learner, your subscription will expire in one hour. Click the link to update your subscription.',
        expired: false,
        reminder: true,
      };
    },
    subscriptionRenewed: (state) => {
      return {
        ...state,
        notification: '',
        expired: false,
        reminder: false,
      };
    },
  },
});

export const { subscriptionHasExpired, subscriptionAboutToExpire, subscriptionRenewed } =
  subscriptionSlice.actions;

export const notificationReducer = subscriptionSlice.reducer;
