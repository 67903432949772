import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './styles.css';
import classNames from '../../utils/classNames';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@mui/material';

export default function PhoneNumberControl({
  value,
  onChange,
  onBlur,
  name,
  label,
  error,
  hideLable = false,
  className,
  required = false,
  placeholder = label,
}) {
  const hasError = !isEmpty(error);
  return (
    <div className={classNames('form-group', className, hasError && 'is-invalid')}>
      {!hideLable && (
        <label htmlFor={name} className={classNames(required && 'required')}>
          <Typography variant='subtitle2'>{startCase(label)}</Typography>
        </label>
      )}
      <PhoneInput
        id={name}
        placeholder={placeholder}
        value={value}
        name={name}
        defaultCountry='KE'
        onChange={onChange}
        onBlur={onBlur}
        className={classNames(
          'phone-input',
          'form-control',
          'form-field',
          hasError && 'is-invalid'
        )}
      />
      {hasError && <div className='invalid-feedback'>{error}</div>}
    </div>
  );
}
