import React from 'react';
import { useLocation } from 'react-router-dom';
import './header.css';
import { routes } from '../../routes';
import RnMLogo from '../../images/ReviseLogo.png';
import Mwalimoo from '../../images/mwalimoo-wave-open-eyes.png';

function Header() {
  const { pathname } = useLocation();
  return (
    <div>
      <>
        <div className='header-logo'>
          <img src={RnMLogo} alt='revise na mwalimoo logo' />
        </div>
        {pathname === routes.paywallPrompt && (
          <div className='image-header'>
            15,000 questions and more just for you!
            <img className='header-image' src={Mwalimoo} alt='Paywall prompt' />
          </div>
        )}
      </>
    </div>
  );
}
export default Header;
