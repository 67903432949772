import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subjectList: [],
  selectedSubject: {},
};

const subjectsSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    subjectSelected(state, action) {
      state.selectedSubject = action.payload;
    },
  },
});

export const { subjectSelected } = subjectsSlice.actions;
export default subjectsSlice.reducer;
