import BaseAPI from './base';
import queryString from 'query-string';

export default class SessionsAPI extends BaseAPI {
  addSessionQuiz(quizPayload) {
    const request = this.sessionApi.post(`/${quizPayload.student_id}`, { ...quizPayload });
    return this.processRequest(request);
  }

  getSessionQuiz(userId, params = {}) {
    const query = queryString.stringify(params);
    const request = this.sessionApi.get(`/${userId}?${query}`);
    return this.processRequest(request);
  }

  createUser(payload) {
    const { phone_number, name, user_id } = payload;
    const request = this.sessionApi.post('/candidate', { phone_number, name, user_id });
    return this.processRequest(request);
  }

  createSession({ last_question_id, search_terms, session_name, user_id, no_of_questions }) {
    const request = this.sessionApi.post('/session', {
      last_question_id,
      search_terms,
      session_name,
      user_id,
      no_of_questions,
    });
    return this.processRequest(request);
  }

  getSearchTermTypes() {
    const request = this.sessionApi.get('/search-term-type');
    return this.processRequest(request);
  }

  createPerformance(performancePayload) {
    const request = this.sessionApi.post('/performance', performancePayload);
    return this.processRequest(request);
  }

  getSessions(params = {}) {
    let query = queryString.stringify({ offset: 0, ...params });
    const request = this.sessionApi.get(`/session?${query}`);
    return this.processRequest(request);
  }

  getUserSessions(userId) {
    const request = this.sessionApi.get(`/session/${userId}`);
    return this.processRequest(request);
  }

  getSessionsSummary(userId, params = { is_complete: true }, sessionIds = []) {
    const query = queryString.stringify({ offset: 0, session_ids: sessionIds, ...params });
    const request = this.sessionApi.get(`/session/${userId}/summary?${query}`);
    return this.processRequest(request);
  }

  updateSession(sessionId, updatePayload) {
    const request = this.sessionApi.put(`/session/${sessionId}`, updatePayload);
    return this.processRequest(request);
  }

  getSessionById(sessionId) {
    const request = this.sessionApi.get(`/session/${sessionId}`);
    return this.processRequest(request);
  }

  getPerformanceSummary(userId) {
    const request = this.sessionApi.get(`/performance/${userId}/summary`);
    return this.processRequest(request);
  }

  getPerfomanceBySessionId(sessionId) {
    const query = queryString.stringify({ session_id: sessionId });
    const request = this.sessionApi.get(`/performance?${query}`);
    return this.processRequest(request);
  }
}

//export an instance of the class also
export const sessionApi = new SessionsAPI();
