// action types
const QUESTIONS_INDEX_SET = 'currentSession/questionIndexSet';
const MULTICHOICE_FETCHED = 'questions/multichoiceFetched';
const MULTICHOICE_STARTED = 'questions/multichoiceStarted';
const MULTICHOICE_COMPLETED = 'questions/multichoiceCompleted';
const CLOZE_TEST_FETCHED = 'questions/clozeTestFetched';
const CLOZE_TEST_STARTED = 'questions/clozeTestStarted';
const CLOZE_TEST_COMPLETED = 'questions/clozeTestCompleted';
const COMPREHENSION_FETCHED = 'questions/comprehensionFetched';
const COMPREHENSION_STARTED = 'questions/comprehensionStarted';
const COMPREHENSION_COMPLETED = 'questions/comprehensionCompleted';
const SESSION_QUESTIONS_RESET = 'questions/sessionQuestionsReset';
const COMPREHENSION_UPDATED = 'questions/comprehensionUpdated';
const COMPREHENSION_CURRENT_QUIZ_RESET = 'questions/resetComprehensionCurrentQuiz';
const SESSION_TYPE_SET = 'questions/sessionTypeSet';

// action creators
export const questionIndexSet = (questionIndex) => {
  return {
    type: QUESTIONS_INDEX_SET,
    payload: questionIndex,
  };
};

export const multichoiceFetched = (questions) => {
  return {
    type: MULTICHOICE_FETCHED,
    payload: {
      available: true,
      status: 'notStarted',
      questions,
    },
  };
};

export const clozeTestFetched = (questions, status = 'notStarted') => {
  return {
    type: CLOZE_TEST_FETCHED,
    payload: {
      available: true,
      status,
      questions,
    },
  };
};

export const comprehensionFetched = (quizzes) => {
  return {
    type: COMPREHENSION_FETCHED,
    payload: {
      available: true,
      status: 'notStarted',
      quizzes,
    },
  };
};

export const sessionQuestionsReset = () => {
  return {
    type: SESSION_QUESTIONS_RESET,
  };
};

export const clozeTestCompleted = () => {
  return {
    type: CLOZE_TEST_COMPLETED,
    payload: {
      status: 'completed',
    },
  };
};

export const multichoiceCompleted = () => {
  return {
    type: MULTICHOICE_COMPLETED,
    payload: {
      status: 'completed',
    },
  };
};

export const comprehensionUpdated = (update) => {
  return {
    type: COMPREHENSION_UPDATED,
    payload: {
      update,
    },
  };
};

export const resetComprehensionCurrentQuiz = () => {
  return {
    type: COMPREHENSION_CURRENT_QUIZ_RESET,
  };
};

export const setSesstionType = (sessionType) => {
  return {
    type: SESSION_TYPE_SET,
    payload: { sessionType }
  }
}

const initialState = {
  multichoice: {
    available: false,
    status: 'notStarted', // other statuses : ongoing, completed
    questions: [],
  },
  clozeTest: {
    available: false,
    status: 'notStarted', // other statuses : ongoing, completed
    questions: [],
  },
  comprehension: {
    available: false,
    status: 'notStarted', // other statuses : ongoing, completed
    quizzes: [],
    currentQuiz: {},
  },
  currentQuestionIndex: 0,
  sessionType: 'new', //other types: pending, revision
};

export default function currentSessionReducer(state = initialState, action) {
  switch (action.type) {

    case QUESTIONS_INDEX_SET: {
      return { ...state, currentQuestionIndex: action.payload };
    }
    case MULTICHOICE_FETCHED: {
      return { ...state, multichoice: action.payload };
    }
    case COMPREHENSION_FETCHED: {
      return { ...state, comprehension: action.payload };
    }
    case CLOZE_TEST_FETCHED: {
      return { ...state, clozeTest: action.payload };
    }
    case MULTICHOICE_STARTED:
    case MULTICHOICE_COMPLETED: {
      return { ...state, multichoice: { ...state.multichoice, status: action.payload.status } };
    }
    case COMPREHENSION_STARTED:
    case COMPREHENSION_COMPLETED: {
      return { ...state, comprehension: { ...state.comprehension, status: action.payload.status } };
    }
    case CLOZE_TEST_STARTED:
    case CLOZE_TEST_COMPLETED: {
      return { ...state, clozeTest: { ...state.clozeTest, status: action.payload.status } };
    }
    case SESSION_QUESTIONS_RESET: {
      return initialState;
    }
    case COMPREHENSION_UPDATED: {
      return {
        ...state,
        comprehension: { ...state.comprehension, ...action.payload.update },
      };
    }
    case COMPREHENSION_CURRENT_QUIZ_RESET: {
      return {
        ...state,
        comprehension: { ...state.comprehension, currentQuiz: {} },
      };
    }
    case SESSION_TYPE_SET: {
      return {
        ...state,
        sessionType: action.payload.sessionType
      }
    }
    default:
      return state;
  }
}
