import { AES, enc } from "crypto-js";
import config from '../config';

export const encryptText = (text) => {
  return AES.encrypt(text, config.secretKey).toString();
};

export const decryptText = (encryptedText) => {
  return AES.decrypt(encryptedText, config.secretKey).toString(enc.Utf8);
};
