import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../routes';
import { getUserFromStorage } from '../../utils/auth';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const AuthRouter = ({ children }) => {
  const user = getUserFromStorage();
  const isAuthenticated = !isEmpty(user);
  const subscription = useSelector((state) => state.subscription);
  const isSubscribed = !isEmpty(subscription);

  if (isAuthenticated) {
    if (isSubscribed) {
      return children;
    }
    return <Navigate to={routes.subscription} />;
  } else {
    return <Navigate to={routes.welcome} />;
  }
};

export default AuthRouter;
