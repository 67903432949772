import React, { useEffect } from 'react';
import './freeTrial.css';
import FreeTrailHero from './free-30-days-trial-hero.png';
import { getUserFromStorage } from '../../utils/auth';
import { subscriptionApi } from '../../api/cms/subscription';
import { routes } from '../../routes/index';
import { checkFreeTrialSubscriptionStatus } from '../../utils/subscription';
import { useNavigate } from 'react-router-dom';

export const FreeTrialPromt = () => {
  const navigate = useNavigate();
  const { phone_number: phoneNumber } = getUserFromStorage();

  useEffect(() => {
    const checkFreeTrial = async (phoneNumber) => {
      const hasReedeemedFreeTrial = await checkFreeTrialSubscriptionStatus(phoneNumber);
      if (hasReedeemedFreeTrial) {
        navigate(routes.dashboard);
      }
    };
    checkFreeTrial(phoneNumber);
  }, []);
  const createFreeTrialSubscription = async () => {
    try {
      const thirtyDaysFreeTrialProductId = 26; //hard-coded for now but might need a way to fetch products and find a particular one

      await subscriptionApi.createSubscription({
        billing_reference_id: phoneNumber.substring(1),
        product_id: thirtyDaysFreeTrialProductId,
      });
      navigate(routes.dashboard);
    } catch (error) {
      // we can just let this fail silently for now
    }
  };
  return (
    <div className='free-trial'>
      <div className='free-trial__image-wrapper'>
        <img src={FreeTrailHero} alt='free trial hero' />
      </div>
      <div className='free-trial__message-wrapper'>
        <div className='free-trial__cta'>
          30 free days of <br /> Revise na Mwalimoo!
        </div>
        <div className='free-trial__message'>
          Thank you for being awesome and using Revise na Mwalimoo! You can now learn on Revise na
          Mwalimoo for free for the next 30 days. After the period ends you will need to pay to
          continue learning.
        </div>
        <div className='free-trial__button-container'>
          <button className='free-trial__button' onClick={() => createFreeTrialSubscription()}>
            start free trial
          </button>
        </div>
      </div>
    </div>
  );
};
