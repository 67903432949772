import React, { useEffect, useState } from 'react';
import './styles.css';
import { routes } from '../../routes';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { otpHandler, getOrganisations } from './AuthComponent';
import SpinnerButton from '../../components/common/SpinnerButton';
import ErrorMessage from '../../components/common/ErrorMessage';
import AuthAPI from '../../api/cms/auth';
import isEmpty from 'lodash/isEmpty';
import PhoneNumberControl from '../../components/common/PhoneNumberControl';
import FormControl from '../../components/common/FormControl';
import SessionsAPI from '../../api/cms/session';
import { encryptText } from '../../utils/crypto';
import Loader from '../../components/common/Loader';
import { Typography } from '@mui/material';
import Image from '../../images/register-hero.png';
import AuthPageComponent from './shared/AuthPageComponet';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    phoneNumber: '',
    password: '',
    firstName: '',
    lastName: '',
    role: {},
    gender: {},
    confirmPassword: '',
    verificationCode: '',
    inValidPhoneNumber: false,
    verifyOtp: false,
    verificationCodeResent: false,
    resendingVerificationCode: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [working, setWorking] = useState(false);
  const [error, setError] = useState('');
  const [organisation, setOrganisation] = useState('');

  const validate = () => {
    let err = {};
    const { phoneNumber, password, firstName, lastName, confirmPassword } = state;
    if (!password) {
      err = { ...err, password: 'Password cannot be empty' };
    }
    if (!firstName) {
      err = { ...err, firstName: 'First name cannot be empty' };
    }
    if (!lastName) {
      err = { ...err, lastName: 'Last name cannot be empty' };
    }
    if (!phoneNumber) {
      err = { ...err, phoneNumber: 'Phone number cannot be empty' };
    }
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      err = { ...err, phoneNumber: 'Please enter a valid phone number' };
    }
    if (!confirmPassword) {
      err = { ...err, confirmPassword: 'Please confirm your password' };
    }
    if (confirmPassword && confirmPassword !== password) {
      err = { ...err, confirmPassword: 'Passwords do not match' };
    }
    return err;
  };

  const handleError = (err) => {
    setWorking(false);
    setError(err);
  };

  const resetError = (name) => {
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const resendOtp = async () => {
    const { phoneNumber: phone_number } = state;
    // call otp endpoint
    setState({ ...state, resendingVerificationCode: true });
    const { error } = await otpHandler({
      phone_number,
      new_user: true,
    });
    setState({ ...state, resendingVerificationCode: false });
    if (error) {
      return handleError(error);
    }
    setState({ ...state, verificationCodeResent: true });
  };

  const onSubmit = async () => {
    try {
      const err = validate();
      if (!isEmpty(err)) return setFormErrors({ ...err });
      const { phoneNumber: phone_number } = state;
      // call otp endpoint
      setWorking(true);
      const { error, payload } = await otpHandler({
        phone_number,
        new_user: true,
      });
      if (error) {
        setWorking(false);
        return handleError(error);
      }
      if (!isEmpty(payload)) {
        setWorking(false);
        setState({ ...state, verifyOtp: true });
      }
    } catch (error) {
      setWorking(false);
      handleError(error);
    }
  };

  const onVerifyOtp = async () => {
    try {
      // verify the otp
      handleError('');
      const { verificationCode: otp, phoneNumber } = state;
      if (!otp)
        return setFormErrors({
          ...formErrors,
          verificationCode: 'Incorrect code, please try again.',
        });
      setWorking(true);
      const { error, payload } = await otpHandler({
        phone_number: phoneNumber,
        otp,
        new_user: true,
      });
      if (error)
        return handleError({
          message: 'We could not verify your code. Please resend code and try again.',
        });
      if (!isEmpty(payload)) {
        handleRegisterUser();
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const getStudentRole = async () => {
    try {
      const api = new AuthAPI();
      const { error, payload } = await api.getRoles({ name: 'STUDENT' });
      if (error) return handleError(error);
      return payload.returned_resultset[0];
    } catch (error) {
      handleError(error);
    }
  };

  const handleRegisterUser = async () => {
    try {
      const { firstName, lastName, phoneNumber, password, confirmPassword, gender } = state;
      if (confirmPassword !== password) return handleError('Password do not match!');
      setWorking(true);
      const role = await getStudentRole();
      const payload = {
        password,
        gender: gender.value,
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        role_id: role?.id,
        user_status_id: 1,
        organisation_id: organisation?.id,
      };
      const api = new AuthAPI();

      const { error, payload: res } = await api.register(payload);

      if (error) {
        const errorMessage =
          error.status === 400
            ? 'This phone number already has an account, please log in.'
            : error.message
            ? error.message
            : error;
        return handleError({ message: errorMessage });
      }
      if (res.returned_resultset) {
        const sessionsAPI = new SessionsAPI();
        const { phone_number, names: name, id: user_id } = res.returned_resultset[0];
        await sessionsAPI.createUser({
          phone_number: phone_number.substring(1),
          name,
          user_id,
        });
      }

      // check subscription
      // redirect to either subscription page of landing page
      localStorage.setItem('user', encryptText(JSON.stringify(res.returned_resultset[0])));
      // redirect to redeem 30 days free trial
      navigate(routes.freeTrial);
    } catch (error) {
      return handleError(error);
    }
  };

  const onChange = (name, value) => {
    if (name === 'phoneNumber') {
      if (isEmpty(value)) value = '';
      setState({ inValidPhoneNumber: false });
    }
    setState({ ...state, [name]: value });
  };

  const {
    phoneNumber,
    password,
    lastName,
    firstName,
    confirmPassword,
    verificationCode,
    verifyOtp,
  } = state;

  const loadOrganisations = async () => {
    const { payload } = await getOrganisations();
    setOrganisation(payload?.returned_resultset[0]);
  };

  useEffect(() => {
    loadOrganisations();
  }, []);

  return (
    <AuthPageComponent PageImage={Image}>
      <div className='mt-3'>
        <Typography variant='h5'>{verifyOtp ? 'Verify phone number' : 'Register'}</Typography>
        <Typography variant='subtitle1'>
          {verifyOtp
            ? 'Hello Student please enter the 6 digit code sent to you by Shupavu291.'
            : 'Enter your details below'}
        </Typography>
      </div>
      <div className='col-12'>{error && <ErrorMessage message={error} />}</div>
      {verifyOtp ? (
        <FormControl
          label='Verification Code'
          value={verificationCode}
          error={formErrors.verificationCode}
          onChange={(value) => {
            resetError('verificationCode');
            onChange('verificationCode', value);
          }}
        />
      ) : (
        <>
          <FormControl
            label='First Name'
            value={firstName}
            error={formErrors.firstName}
            onChange={(value) => {
              resetError('firstName');
              onChange('firstName', value);
            }}
          />

          <FormControl
            label='Last Name'
            error={formErrors.lastName}
            value={lastName}
            onChange={(value) => {
              resetError('lastName');
              onChange('lastName', value);
            }}
          />

          <PhoneNumberControl
            placeholder='0712 345 678'
            label='Mobile Number'
            value={phoneNumber}
            error={formErrors.phoneNumber}
            onChange={(value) => {
              resetError('phoneNumber');
              onChange('phoneNumber', value);
            }}
          />
          <FormControl
            label='Password'
            type='password'
            value={password}
            error={formErrors.password}
            onChange={(value) => {
              resetError('password');
              onChange('password', value);
            }}
          />
          <FormControl
            label='Confirm Password'
            type='password'
            error={formErrors.confirmPassword}
            value={confirmPassword}
            onChange={(event) => {
              resetError('confirmPassword');
              onChange('confirmPassword', event);
            }}
          />
        </>
      )}
      <SpinnerButton
        onClick={verifyOtp ? onVerifyOtp : onSubmit}
        working={working}
        title={verifyOtp ? 'Verify' : 'Continue'}
      />
      <Typography variant='subtitle2' sx={{ mt: 1 }} onClick={() => navigate(routes.login)}>
        Already have an account? <span className='register-form__resend-code'>Login.</span>
      </Typography>
      {state.resendingVerificationCode && <Loader />}
      {state.verificationCodeResent && (
        <Typography variant='caption'>
          Please enter the new 6 digit code sent to you by Shupavu291
        </Typography>
      )}
      {verifyOtp && !state.resendingVerificationCode && (
        <>
          <p onClick={() => resendOtp()} className='register-form__resend-code'>
            Resend code?
          </p>
          <Typography variant='caption'>
            Please dial *100*5*2*5*1# to enable you to receive promotional messages from Shupavu291
          </Typography>
        </>
      )}
    </AuthPageComponent>
  );
}

export default Register;
