import React, { useState } from 'react';
import { routes } from '../../../routes/index';
import './login.css';
import FormControl from '../../../components/common/FormControl';
import PhoneNumberControl from '../../../components/common/PhoneNumberControl';
import SpinnerButton from '../../../components/common/SpinnerButton';
import AuthAPI from '../../../api/cms/auth';
import { setToken } from '../../../utils/auth';
import { encryptText } from '../../../utils/crypto';
import { Grid, Typography } from '@mui/material';
import Image from '../../../images/create-account-hero-image.png';
import AuthPageComponent from '../shared/AuthPageComponet';
import { checkFreeTrialSubscriptionStatus } from '../../../utils/subscription';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    phoneNumber: '',
    password: '',
  });
  const [working, setWorking] = useState(false);
  const [apiError, setApiError] = useState(null);
  const api = new AuthAPI();

  const onChange = (name, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onSubmit = async () => {
    setWorking(true);
    const { error, payload } = await api.login({
      phone_number: state.phoneNumber,
      password: state.password,
    });

    setWorking(false);
    if (error) {
      setApiError(
        error.status === 404
          ? {
              message: 'You have put the wrong phone number or password. Please try again.',
            }
          : error.message
          ? error
          : { message: 'Sorry, that is our fault. Please try again.' },
      );
    } else {
      localStorage.setItem('user', encryptText(JSON.stringify(payload.user)));
      const { access_token } = payload;
      setToken(access_token);
      const hasRedeemedFreeTrial = await checkFreeTrialSubscriptionStatus(state.phoneNumber);
      if (hasRedeemedFreeTrial) {
        navigate(routes.dashboard);
      } else {
        navigate(routes.freeTrial);
      }
    }
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <AuthPageComponent PageImage={Image}>
      <Typography sx={{ fontSize: { xs: '1.8rem', md: '2.125rem' } }} variant='h4'>
        Take control of how you revise for your exams{' '}
        <span style={{ color: '#1c97c3' }}>&rarr;</span>{' '}
      </Typography>

      {apiError && (
        <div className='alert alert-danger alert-dismissible fade show' role='alert'>
          {apiError.message}
          <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
            <span onClick={() => clearApiError()} aria-hidden='true'>
              &times;
            </span>
          </button>
        </div>
      )}
      <PhoneNumberControl
        placeholder='0712 345 678'
        label='Mobile Number'
        value={state.phoneNumber}
        onChange={(value) => onChange('phoneNumber', value)}
      />
      <FormControl
        label='Password'
        type='password'
        value={state.password}
        onChange={(value) => onChange('password', value)}
      />
      <Grid container alignItems='center'>
        <Grid xs={6} item container justifyContent='start'>
          <div className='forgort-password'>
            <p onClick={() => navigate(routes.resetPassword)}>Forgot password?</p>
          </div>
        </Grid>
        <Grid xs={6} item container justifyContent='end'>
          <SpinnerButton fullWidth working={working} onClick={() => onSubmit()} title={'Login'} />
        </Grid>
      </Grid>
      <div className='new-user-button-container mt-2'>
        <Typography variant='body1'>
          Do not have the accout yet?{' '}
          <span role='button' onClick={() => navigate(routes.register)} className='register-button'>
            register here &rarr;
          </span>
        </Typography>
      </div>
    </AuthPageComponent>
  );
}

export default Login;
