import { configureStore } from '@reduxjs/toolkit';
import filterReducer from '../views/Filter/filterSlice';
import subjectReducer from '../views/Subjects/subjectsSlice';
import subscriptionReducer, { notificationReducer } from '../views/Subscription/subscriptionSlice';
import currentSessionReducer from '../views/Questions/questionsSlice';
import { apiSlice } from './apiSlice';

const store = configureStore({
  reducer: {
    filter: filterReducer,
    subject: subjectReducer,
    subscription: subscriptionReducer,
    currentSession: currentSessionReducer,
    notification: notificationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
export default store;
