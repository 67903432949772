import axios from 'axios';
import parseResponse from '../../utils/parseResponse';
import config from '../../config';
import { getToken } from '../../utils/auth';

export default class BaseAPI {
  headers = { 'Content-Type': 'application/json' };
  constructor() {
    if (localStorage.getItem('adminToken')) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${getToken('adminToken')}`,
      };
    }
    this.api = axios.create({
      baseURL: config.cmsUrl,
      headers: {
        ...this.headers,
      },
    });
    this.authApi = axios.create({
      baseURL: config.authUrl,
      headers: {
        ...this.headers,
        headers: {
          ...this.headers,
          Authorization: `Bearer ${getToken()}`,
        },
      },
    });
    this.sessionApi = axios.create({
      baseURL: config.sessionUrl,
      headers: {
        ...this.headers,
        Authorization: `Bearer ${config.sessionApiKey}`,
      },
    });
    this.subscriptionApi = axios.create({
      baseURL: config.subscriptionUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  processRequest(request) {
    return parseResponse(request);
  }
}
