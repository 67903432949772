import { Alert } from '@mui/material';
import React, { useState } from 'react';

export default function ErrorMessage({ message: error, isVisible = true, onClearError }) {
  const [visible, setVisible] = useState(isVisible);
  let message;
  if (typeof error === 'string') message = error;
  else message = error.message;

  const onDismiss = () => {
    setVisible(false);
    if (onClearError) {
      onClearError();
    }
  };
  return (
    <>
      {visible && (
        <Alert severity='error' variant='filled' onClose={onDismiss} dismissible>
          {message}
        </Alert>
      )}
    </>
  );
}
