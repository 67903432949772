import AuthAPI from '../../api/cms/auth';
import { getToken, setToken } from '../../utils/auth';
import { Buffer } from 'buffer';

export const adminAuthenticate = async () => {
  try {
    const api = new AuthAPI();
    const { REACT_APP_ADMIN_USER, REACT_APP_ADMIN_USER_SECRETS } = process.env;
    const email = Buffer.from(REACT_APP_ADMIN_USER, 'base64').toString('ascii');
    const password = Buffer.from(REACT_APP_ADMIN_USER_SECRETS, 'base64').toString('ascii');
    const { error, payload } = await api.login({ email, password });
    if (error) return error;
    const { access_token } = payload;
    setToken(access_token, 'adminToken');
  } catch (error) {
    return error;
  }
};

export const otpHandler = async (payload) => {
  try {
    await adminAuthenticate();
    const api = new AuthAPI();
    return await api.sendOrVerifyOtp(payload);
  } catch (error) {
    return { error };
  }
};

export const getOrganisations = async () => {
  try {
    await adminAuthenticate();
    const api = new AuthAPI();
    return await api.getOrganisations();
  } catch (error) {
    return { error };
  }
};
