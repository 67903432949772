import React from 'react';
import { routes } from '../../routes/index';
import './styles.css';
import logo from '../../images/ReviseLogo.png';
import EnezaLogo from '../../images/footer-logo.png';
import ImageSlider from '../../components/ImageSlider';
import chooseTopicImage from '../../images/choose-topic.png';
import chooseYearImage from '../../images/choose-year.png';
import topicAndYear from '../../images/topic-and-year.png';
import englishClozeTestQuestion from '../../images/english-cloze-test-question.png';
import englishClozeTestAnswer from '../../images/english-cloze-test-answer.png';
import kiswahiliClozeTestQuestion from '../../images/kiswahili-cloze-test-question.png';
import kiswahiliClozeTestAnswer from '../../images/kiswahili-closze-test-answer.png';
import englishComprehensionPassage from '../../images/english-comprehension-passage.png';
import englishComprehensionQuestion from '../../images/english-comprehension-questions.png';
import contentScicenceQuestion from '../../images/content-science-question.png';
import scienceWrongAnswer from '../../images/science-wrong-answer.png';
import contentMathsQuestion from '../../images/content-maths-question.png';
import mathsCorrectAnswer from '../../images/maths-correct-answer.png';
import thirtyDaysFreeTrialHero from '../../images/revise-na-Mwalimoo-landing-page-hero-image.png';
import { Link } from 'react-router-dom';

export default function LandingPage() {
  const filterSliderImages = [
    {
      url: chooseYearImage,
      title: 'choose year',
    },
    {
      url: chooseTopicImage,
      title: 'choose Topic',
    },
    {
      url: topicAndYear,
      title: 'topic and year',
    },
  ];

  const brokenPassageImages = [
    {
      url: englishClozeTestQuestion,
      title: 'english broken passage',
    },
    {
      url: englishClozeTestAnswer,
      title: 'english broken passage answer',
    },
    {
      url: kiswahiliClozeTestQuestion,
      title: 'kiswahili broken passage',
    },
    {
      url: kiswahiliClozeTestAnswer,
      title: 'kiswahili broken passage answer',
    },
  ];

  const comprehensionImages = [
    {
      url: englishComprehensionPassage,
      title: 'comprehension passage',
    },
    {
      url: englishComprehensionQuestion,
      title: 'comprehension question',
    },
  ];

  const contentImages = [
    {
      url: contentScicenceQuestion,
      title: 'science question',
    },
    {
      url: scienceWrongAnswer,
      title: 'wrong answer',
    },
    {
      url: contentMathsQuestion,
      title: 'maths question',
    },
    {
      url: mathsCorrectAnswer,
      title: 'correct answer',
    },
  ];

  return (
    <div data-testid='welcome' className='landing-page'>
      <div className='landing-page-header'>
        <img className='landing-logo' src={logo} alt='logo' />
      </div>

      <section className='landing-page-section landing-page-first-section'>
        <div className='landing-page-subsection hero-image-container'>
          <img src={thirtyDaysFreeTrialHero} alt='thirty days hero' />
        </div>
        <div className='landing-page-subsection hero-text-container'>
          <div className='thirty-days-free-trial__cta'>
            Start your 30 day <br /> free trial today!
          </div>
          <div className='thirty-days-free-trial__message'>
            Succeed in your KCPE <br /> with Teacher Mwalimoo
          </div>
          <div className='thirty-days-free-trial__buttons'>
            <Link to={routes.register} className='section-btns start-learning__btn'>
              Register now
            </Link>
            <Link to={routes.login} className='section-btns free-trial__login-btn'>
              login
            </Link>
          </div>
          <div>
            <a href='#revisionModes' className='show-more__link'>
              what is revise na mwalimoo?
            </a>
          </div>
        </div>
      </section>

      <section className='flex-column light-blue-background' id='revisionModes'>
        <div className='revision-modes-container'>
          <div className='revision-modes'>
            <p>
              Choose the way you want <br /> to revise subjects that you <br /> are weak in
            </p>
          </div>

          <div className='slider-container'>
            <ImageSlider slides={filterSliderImages} />
          </div>
        </div>

        <div className='show-more-container'>
          <a href='#brokenPassage' className='btn show-more-button' role='button'>
            Show More
          </a>
        </div>
      </section>

      <section
        className='flex-column landing-page-fourth-section light-blue-background'
        id='brokenPassage'
      >
        <div className='revision-modes-container'>
          <div className='revision-modes'>
            <p className='revision-modes'>
              Get better at answering <br /> English &amp; Kiswahili broken <br /> passages
            </p>
          </div>
          <div className='slider-container'>
            <ImageSlider slides={brokenPassageImages} />
          </div>
        </div>

        <div className='show-more-container'>
          <a href='#comprehension' className='btn show-more-button' role='button'>
            Show More
          </a>
        </div>
      </section>

      <section className='flex-column light-blue-background' id='comprehension'>
        <div className='revision-modes-container'>
          <div className='revision-modes comprehension-section'>
            <p>
              Get better at <br /> comprehension and <br /> ufahamu questions
            </p>
          </div>
          <div className='slider-container'>
            <ImageSlider slides={comprehensionImages} />
          </div>
        </div>

        <div className='show-more-container'>
          <a href='#questionAndAnswer' className='btn show-more-button' role='button'>
            Show More
          </a>
        </div>
      </section>

      <section className='flex-column light-blue-background' id='questionAndAnswer'>
        <div className='revision-modes-container'>
          <div className='revision-modes'>
            <p className='revision-modes'>
              Get real time explanations <br /> from Teacher Mwalimoo
            </p>
          </div>
          <div className='slider-container'>
            <ImageSlider slides={contentImages} />
          </div>
        </div>

        <div className='show-more-container'>
          <a href='#getStarted' className='btn show-more-button' role='button'>
            I&#39;M Ready
          </a>
        </div>
      </section>

      <section className='flex-column auth-section' id='getStarted'>
        <div className='register-cta-container'>
          <div className='register-cta'>
            <div className='register-hero'></div>
          </div>
          <div className='landing-page-register-container flex-column'>
            <p>
              Are you ready <br /> to pass your exams?
            </p>
            <Link to={`${routes.register}`} className='btn landing-page-register-button'>
              Register Now
            </Link>
          </div>
        </div>

        <div className='login-cta-container'>
          <div className='login-cta'>
            <p>
              Pick up from <span className='line-break'></span> where you left off
            </p>
          </div>
          <div>
            <Link to={`${routes.login}`} className='btn footer-login-button'>
              login
            </Link>
          </div>
        </div>
      </section>

      <div className='footer'>
        <div className='footer-logo my-1'>
          Powered by <img src={EnezaLogo} className='ml-1 eneza-logo' />
        </div>
      </div>
    </div>
  );
}
