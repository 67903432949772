import axios from 'axios';
import queryString from 'query-string';
import { subscriptionApi } from '../api/cms/subscription';
import config from '../config';
import { isDateTimeAfter } from './date';

export const getActiveSubscription = async (phone_number) => {
  const productIdsMap = {
    dailySubscription: 11,
    monthlySubscription: 12,
    yearlySubscription: 13,
    twoDaysFreeTrial: 24,
    thirtyDaysFreeTrial: 26,
  };

  const params = {
    product_ids: [...Object.values(productIdsMap)],
    billing_reference_id: phone_number,
  };
  const query = queryString.stringify(params);
  const { data } = await axios.get(
    `${config.subscriptionUrl}/subscription?${query}`,
  );
  const { returned_resultset } = data;
  const activeSubscription = returned_resultset
    .filter((sub) => sub.billing_reference_id === phone_number)
    .filter((sub) => isDateTimeAfter(sub.due_date, new Date()));
  return activeSubscription;
};

export const checkFreeTrialSubscriptionStatus = async (phoneNumber) => {
  const thirtyDaysFreeTrialProductId = 26;
  const { payload } = await subscriptionApi.getSubscription({
    product_ids: [thirtyDaysFreeTrialProductId],
    billing_reference_id: phoneNumber.substring(1),
  });
  return !!payload.returned_resultset.length;
};
