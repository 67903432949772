import React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Card)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  border: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  margin: 'auto',
}));

function AuthPageComponent({ children, PageImage }) {
  return (
    <Box sx={{ flexGrow: 1 }} data-testid='login'>
      <Grid spacing={0} container sx={{ background: '#ffffff' }}>
        <Grid sx={{ display: { xs: 'block', md: 'none' } }} item xs={12}>
          <Box
            sx={{
              background: '#1c97c312',
              height: '300px',
              backgroundImage: `url(${PageImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ background: '#ffffff', height: { md: '100vh', xs: '100%' } }}
        >
          <Item elevation={0} sx={{ width: { xs: '100%', md: '80%' } }}>
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
          </Item>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} item xs={12} md={7}>
          <Box
            sx={{
              background: '#1c97c312',
              height: '100vh',
              clipPath: 'polygon(14% 0%, 100% 0, 100% 100%, 0% 100%)',
              backgroundImage: `url(${PageImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AuthPageComponent;
