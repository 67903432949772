const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  selectedTopics: [],
  selectedYears: [],
  selectedYearsTagIds: [],
  session: {},
  level: {},
  quizType: {},
  quizzes: [],
  numberOfQuestions: 0,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    topicsSelected(state, action) {
      state.selectedTopics = action.payload;
    },
    resetTopics(state) {
      state.selectedTopics = [];
    },
    yearsSelected(state, action) {
      state.selectedYears = action.payload;
    },
    yearTagIdsFetched(state, action) {
      state.selectedYearsTagIds = action.payload;
    },
    sessionCreated(state, action) {
      state.session = action.payload;
    },
    levelSelected(state, action) {
      state.level = action.payload;
    },
    resetYears(state) {
      state.selectedYears = [];
      state.selectedYearsTagIds = [];
    },
    quizTypeSet(state, action) {
      state.quizType.name = action.payload;
    },
    resetQuizType(state) {
      state.quizType = {};
    },
    quizzesFetched(state, action) {
      state.quizzes = action.payload;
    },
    quizzesUpdated(state, action) {
      state.quizzes = action.payload;
    },
    numberOfQuestionsSet(state, action) {
      state.numberOfQuestions = action.payload;
    },
    resetFilter: () => initialState,
  },
});

export const {
  topicsSelected,
  resetTopics,
  yearsSelected,
  yearTagIdsFetched,
  sessionCreated,
  levelSelected,
  resetYears,
  quizTypeSet,
  resetQuizType,
  quizzesFetched,
  quizzesUpdated,
  numberOfQuestionsSet,
  resetFilter,
} = filterSlice.actions;
export default filterSlice.reducer;
