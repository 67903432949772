import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { routes } from '../../../routes/index';
import './resetPassword.css';
import PhoneNumberControl from '../../../components/common/PhoneNumberControl';
import FormControl from '../../../components/common/FormControl';
import SpinnerButton from '../../../components/common/SpinnerButton';
import { authApi } from '../../../api/cms/auth';
import { FaArrowLeft } from 'react-icons/fa';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { isEmpty } from 'lodash';
import Image from '../../../images/login-hero-image.png';
import { Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import AuthPageComponent from '../shared/AuthPageComponet';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    verificationCode: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });
  const [working, setWorking] = useState(false);
  const [error, setError] = useState('');

  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [isCodeVerified, setCodeVerified] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [verificationCodeResent, setVerificationCodeResent] = useState(false);

  const resetError = (name) => {
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const onChange = (name, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validate = () => {
    let err = {};
    const { phoneNumber, password, confirmPassword, verificationCode } = state;
    if (!password) {
      err = { ...err, password: 'Password cannot be empty' };
    }
    if (verificationCode.length < 6) {
      err = { ...err, verificationCode: 'Please enter all numbers on the verification code' };
    }
    if (!verificationCode) {
      err = { ...err, verificationCode: 'Verification code cannot be empty' };
    }
    if (!phoneNumber) {
      err = { ...err, phoneNumber: 'Phone number cannot be empty' };
    }
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      err = { ...err, phoneNumber: 'Please enter a valid phone number' };
    }
    if (!confirmPassword) {
      err = { ...err, confirmPassword: 'Please confirm your password' };
    }
    if (confirmPassword && confirmPassword !== password) {
      err = { ...err, confirmPassword: 'Passwords do not match' };
    }
    return err;
  };

  const getOtp = async (phoneNumber) => {
    const { phoneNumber: phoneNumberError } = validate();
    if (!isEmpty(phoneNumberError)) return setFormErrors({ phoneNumber: phoneNumberError });
    setWorking(true);
    const { error } = await authApi.getOtp(phoneNumber);
    if (error) {
      setError('could not send otp, please try again');
    } else {
      setVerificationCodeSent(true);
    }
    setWorking(false);
  };

  const resendOtp = async () => {
    await getOtp(state.phoneNumber);
    setVerificationCodeResent(true);
  };

  const verifyOtp = async () => {
    setError('');
    const { verificationCode: verificationCodeError } = validate();
    if (!isEmpty(verificationCodeError))
      return setFormErrors({ verificationCode: verificationCodeError });
    setWorking(true);
    const { error } = await authApi.verifyResetPasswordOtp({
      phoneNumber: state.phoneNumber,
      otp: state.verificationCode,
    });
    if (error) {
      setError('We could not verify your code. Please resend and try again.');
    } else {
      setCodeVerified(true);
    }
    setWorking(false);
  };

  const resetPassword = async () => {
    const { password: passwordError, confirmPassword: confirmPasswordError } = validate();
    if (!isEmpty(passwordError) || !isEmpty(confirmPasswordError)) {
      return setFormErrors({ password: passwordError, confirmPassword: confirmPasswordError });
    }
    setWorking(true);
    const { error } = await authApi.resetPassword({
      phoneNumber: state.phoneNumber,
      new_password: state.password,
    });
    if (error) {
      setError('could not reset password, please try again');
      setWorking(false);
    } else {
      setWorking(false);
      navigate(routes.login);
    }
  };

  return (
    <AuthPageComponent PageImage={Image}>
      <div className='reset-password-info-container'>
        <Typography variant='h5'>Reset Password</Typography>
        {error && <ErrorMessage message={error} onClearError={() => setError('')} />}
        {!verificationCodeSent && !isCodeVerified && (
          <div>
            <Typography variant='subtitle1'>Enter your mobile number</Typography>
            <PhoneNumberControl
              label='Mobile Number'
              value={state.phoneNumber}
              error={formErrors.phoneNumber}
              onChange={(value) => {
                resetError('phoneNumber');
                onChange('phoneNumber', value);
              }}
            />

            <SpinnerButton
              working={working}
              onClick={() => getOtp(state.phoneNumber)}
              title={'Submit'}
            />
            <p className='reset-password__arrow--back mt-2' onClick={() => navigate(routes.login)}>
              <FaArrowLeft /> Back to login
            </p>
          </div>
        )}
        {verificationCodeSent && !isCodeVerified && (
          <div className='verification-code'>
            {isEmpty(error) && (
              <Typography variant='subtitle2' color='primary'>
                {' '}
                Enter the code sent to your phone by shupavu291 <CheckCircle />
              </Typography>
            )}
            <FormControl
              label='Verification Code'
              value={state.verificationCode}
              error={formErrors.verificationCode}
              onChange={(value) => {
                resetError('verificationCode');
                onChange('verificationCode', value);
              }}
            />
            <div className='verification-code__resend-container'>
              <p onClick={() => resendOtp()} className='verification-code__resend'>
                Resend code
              </p>
              {verificationCodeResent && <p>Enter the new code sent to your phone by shupavu291</p>}
            </div>
            <SpinnerButton working={working} onClick={() => verifyOtp()} title={'verify code'} />
          </div>
        )}
        {isCodeVerified && (
          <div className='new-password-container'>
            <h6>Enter your new password</h6>
            <p>You will be required to login after changing your password</p>
            <FormControl
              type='password'
              label='New Password'
              error={formErrors.password}
              value={state.password}
              onChange={(value) => {
                resetError('password');
                onChange('password', value);
              }}
            />

            <FormControl
              type='password'
              label='Confirm Password'
              error={formErrors.confirmPassword}
              value={state.confirmPassword}
              onChange={(value) => {
                resetError('confirmPassword');
                onChange('confirmPassword', value);
              }}
            />
            <SpinnerButton
              working={working}
              onClick={() => resetPassword()}
              title={'Reset Password'}
            />
          </div>
        )}
      </div>
    </AuthPageComponent>
  );
}

export default ResetPassword;
