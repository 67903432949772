import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { routes } from './routes';
import Loader from './components/common/Loader';
import Login from './views/Auth/Login/Login';
import Register from './views/Auth/Register';
import ResetPassword from './views/Auth/ResetPassword/ResetPassword';
import LandingPage from './views/Landing';
import ReactGA from 'react-ga';
import { FreeTrialPromt } from './views/Subscription/FreeTrial';
import AppLayout from './components/common/AppLayout';
import AuthRouter from './components/AuthRouter';

const SubscriptionPage = lazy(() => import('./views/Subscription/SubscriptionPage'));
const Question = lazy(() => import('./views/Questions'));
const Subjects = lazy(() => import('./views/Subjects'));
const SelectedSubject = lazy(() => import('./views/Subjects/SelectedSubject'));
const Topic = lazy(() => import('./views/Filter/Topic'));
const YearFilter = lazy(() => import('./views/Filter/Year'));
const FilterResult = lazy(() => import('./views/Filter/FilterResult'));
const SessionName = lazy(() => import('./views/Filter/SessionName'));
const QuestionsMeanScore = lazy(() => import('./views/Questions/MeanScore'));
const SessionGrid = lazy(() => import('./views/Session/SessionGrid'));
const Performance = lazy(() => import('./views/Session/Performance'));
const Profile = lazy(() => import('./views/Profile/Profile'));
const MainMenu = lazy(() => import('./views/MainMenu/MainMenu'));
const CompletedSessionQuestions = lazy(() => import('./views/Questions/CompletedSessionQuestions'));
const PendingSessionQuestions = lazy(() => import('./views/Questions/PendingSessionQuestions'));
const ComprehensionPassage = lazy(() => import('./views/Subjects/ComprehensionPassage'));
const CloseTest = lazy(() => import('./views/Subjects/CloseTest'));
const PaywallPrompt = lazy(() => import('./views/PaywallPrompt'));

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-LFKZ3R4N70');
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={routes.freeTrial} element={<FreeTrialPromt />} />
        <Route path={routes.welcome} element={<LandingPage />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.register} element={<Register />} />
        <Route path={routes.dashboard} element={<AppLayout />}>
          <Route
            index
            element={
              <AuthRouter>
                <Subjects />
              </AuthRouter>
            }
          />
          <Route
            path={routes.revision}
            element={
              <AuthRouter>
                <CompletedSessionQuestions />
              </AuthRouter>
            }
          />
          <Route
            path={routes.pendingSession}
            element={
              <AuthRouter>
                <PendingSessionQuestions />
              </AuthRouter>
            }
          />
          <Route
            path={routes.subject}
            element={
              <AuthRouter>
                <SelectedSubject />
              </AuthRouter>
            }
          />
          <Route
            path={routes.questions}
            element={
              <AuthRouter>
                <Question />
              </AuthRouter>
            }
          />
          <Route
            path={routes.topic}
            element={
              <AuthRouter>
                <Topic />
              </AuthRouter>
            }
          />
          <Route
            path={routes.year}
            element={
              <AuthRouter>
                <YearFilter />
              </AuthRouter>
            }
          />
          <Route
            path={routes.selection}
            element={
              <AuthRouter>
                <FilterResult />
              </AuthRouter>
            }
          />
          <Route
            path={routes.sessionName}
            element={
              <AuthRouter>
                <SessionName />
              </AuthRouter>
            }
          />
          <Route
            path={routes.subscription}
            element={
              <AuthRouter>
                <SubscriptionPage />
              </AuthRouter>
            }
          />
          <Route
            path={routes.sessionMeanScore}
            element={
              <AuthRouter>
                <QuestionsMeanScore />
              </AuthRouter>
            }
          />
          <Route
            path={routes.mySessions}
            element={
              <AuthRouter>
                <SessionGrid />
              </AuthRouter>
            }
          />
          <Route
            path={routes.performance}
            element={
              <AuthRouter>
                <Performance />
              </AuthRouter>
            }
          />
          <Route
            path={routes.profile}
            element={
              <AuthRouter>
                <Profile />
              </AuthRouter>
            }
          />
          <Route
            path={routes.mainMenu}
            element={
              <AuthRouter>
                <MainMenu />
              </AuthRouter>
            }
          />
          <Route
            path={routes.comprehensionPassage}
            element={
              <AuthRouter>
                <ComprehensionPassage />
              </AuthRouter>
            }
          />
          <Route
            path={routes.closeTest}
            element={
              <AuthRouter>
                <CloseTest />
              </AuthRouter>
            }
          />
          <Route
            path={routes.paywallPrompt}
            element={
              <AuthRouter>
                <PaywallPrompt />
              </AuthRouter>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
