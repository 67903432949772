import React from 'react'
import classNames from 'classnames';
import { Button } from '@mui/material';
export default function SpinnerButton({
  working,
  type = 'button',
  title,
  onClick,
  disabled= false,
  className,
  fullWidth = true
}) {
  return (
    <Button fullWidth={fullWidth} variant='outlined' color='primary' onClick={onClick} type={type} disabled={working || disabled} className={classNames(className)}>
     {working && <span className={classNames('spinner-border', 'spinner-border-sm', 'btn-spinner')} />} {title}
    </Button>
  )
}
