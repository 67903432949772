import React from 'react'
import { Spinner } from 'react-bootstrap'
import './styles.css'

export default function Loader() {
  return (
    <div className="loader-container" role='button'>
      <Spinner animation='grow' className='spinner' />
    </div>
  )
}
