import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import config from './config';
import ErroMessage from './components/common/ErrorMessage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1C99C5',
    },
  },
});

Bugsnag.start({
  apiKey: config.bugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const ErrorView = () => <ErroMessage message={'something went wrong, try again later'} />;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
