import BaseAPI from './base';
import queryString from 'query-string';

export default class SubscriptionAPI extends BaseAPI {
  processPayment(subscriptionPayload) {
    const request = this.subscriptionApi.post(`/payment/mpesa`, {
      ...subscriptionPayload,
    });
    return this.processRequest(request);
  }

  getSubscription(params = {}) {
    const query = queryString.stringify(params);
    const request = this.subscriptionApi.get(`/subscription?${query}`);
    return this.processRequest(request);
  }
  getSubscriptionProduct(id) {
    const request = this.subscriptionApi.get(`/product/${id}`);
    return this.processRequest(request);
  }

  createSubscription(payload) {
    const request = this.subscriptionApi.post('/subscription', payload);
    return this.processRequest(request);
  }
}

export const subscriptionApi = new SubscriptionAPI();
