import BaseAPI from './base';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

export default class AuthAPI extends BaseAPI {
  login(payload) {
    const request = this.authApi.post('/auth/login', { ...payload });
    return this.processRequest(request);
  }

  register(payload) {
    const request = this.authApi.post('/user', { ...payload });
    return this.processRequest(request);
  }

  sendOrVerifyOtp(payload) {
    const request = this.authApi.post('/otp', { ...payload });
    return this.processRequest(request);
  }

  getRoles(params = {}) {
    let query = {};
    if (!isEmpty(params)) query = queryString.stringify(params);
    const request = this.authApi.get(`/role?${query}`);
    return this.processRequest(request);
  }

  getOrganisations(params = {}) {
    let query = queryString.stringify({ name: 'Eneza Kenya', ...params });
    const request = this.authApi.get(`/organisation?${query}`);
    return this.processRequest(request);
  }

  getOtp(phoneNumber) {
    const request = this.authApi.post(`/auth/password/reset/initiate`, {
      phone_number: phoneNumber,
    });
    return this.processRequest(request);
  }

  verifyResetPasswordOtp({ phoneNumber, otp }) {
    const request = this.authApi.post(`/auth/password/reset/verify-otp`, {
      phone_number: phoneNumber,
      otp,
    });
    return this.processRequest(request);
  }

  resetPassword({ phoneNumber, new_password }) {
    const request = this.authApi.post(`/auth/password/reset`, {
      phone_number: phoneNumber,
      new_password,
    });
    return this.processRequest(request);
  }

  updateUser(userId, update) {
    const request = this.authApi.put(`/user/${userId}`, {
      ...update,
    });
    return this.processRequest(request);
  }
}

export const authApi = new AuthAPI();
