import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import './styles.css';

export default function AppLayout() {
  const styles = {
    appLayout: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  };
  return (
    <div style={styles.appLayout}>
      <Header />
      <div className='page-wrapper'>
        <div className='main-content'>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
