export const routes = {
  dashboard: '/',
  login: '/login',
  resetPassword: '/resetpassword',
  welcome: '/welcome',
  register: '/register',
  questions: '/questions',
  quizzes: '/quizzes',
  level: '/level',
  subscription: '/subscription',
  subject: '/subjects/:subjectName',
  topic: '/topic',
  year: '/year',
  selection: '/selection',
  sessionName: '/sessionName',
  sessionMeanScore: '/session/meanscore',
  mySessions: '/mySessions',
  performance: '/performance',
  profile: '/profile',
  mainMenu: '/menu',
  revision: '/revision',
  pendingSession: '/session/pending',
  quizTypeFilter: '/quiz-type-filter',
  comprehensionPassage: '/comprehension-passage',
  closeTest: '/close-test',
  paywallPrompt: '/paywall-prompt',
  classLevel: '/class',
  freeTrial: '/free-trial',
};
